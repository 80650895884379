<template>
  <div>
    <EditManufacturer />
    <table class="table table-responsive-md table-sm">
      <thead class="dark">
        <tr>
          <th scope="col" class="text-center">Sr No.</th>
          <th scope="col" class="text-center">Name</th>
          <th scope="col" class="text-center">Email</th>
          <th scope="col" class="text-center">Phone</th>
          <th scope="col" class="text-center">City</th>
          <th scope="col" class="text-center">Country</th>
          <th scope="col" class="text-center">Address</th>
          <th scope="col" class="text-center">Status</th>
          <th scope="col" class="text-center">Action</th>
        </tr>
      </thead>

      <tbody v-if="showLoader == 1">
        <tr class="shimmer-td">
          <td colspan="9">
            <content-placeholders class="shimmer" :rounded="true">
              <content-placeholders-text :lines="10" />
            </content-placeholders>
          </td>
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0 && displayedManufacturers.length > 0">
        <tr
          v-for="(manufacturer, index) in displayedManufacturers"
          :key="index"
        >
          <td>
            <div class="company-link">
              <span>M-{{ index+1 }}</span>
            </div>
          </td>
          <td v-if="manufacturer.picture == null">
            <div class="company-details">
              <div class="company-logo">
                <img src="../../assets/images/user-image.png" alt="" />
              </div>
              <h6 class="company-name">{{ manufacturer.manufacturer_name }}</h6>
            </div>
          </td>
          <td v-else>
            <div class="company-details">
              <div class="company-logo">
                <img :src="API_URL + manufacturer.picture" alt="" />
              </div>
              <h6 class="company-name">{{ manufacturer.manufacturer_name }}</h6>
            </div>
          </td>
          <td>
            <div class="company-link">
              <span>{{ manufacturer.email }}</span>
            </div>
          </td>
          <td>
            <div class="company-size">
              <span>{{ manufacturer.phone }}</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>{{ manufacturer.city }}</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>{{ manufacturer.country }}</span>
            </div>
          </td>
          <td>
            <div class="company-location">
              <span>{{ manufacturer.address }}</span>
            </div>
          </td>
          <td v-if="manufacturer.status == 1" class="text-center">
            <button class="active">Active</button>
          </td>
          <td v-else-if="manufacturer.status == 2" class="text-center">
            <button class="terminated">Not Active</button>
          </td>
          <td class="text-center">
            <div class="actions-btn">
              <i class="fa fa-pencil-alt" @click="callModal(manufacturer)"> </i>
              <i
                class="fa fa-trash"
                @click="deleteManufacturer(manufacturer.id)"
              >
              </i>
            </div>
          </td>
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0 && displayedManufacturers.length == 0">
        <tr class="no-data">
          <td colspan="9" class="shimmer">
            No Data Found.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import EditManufacturer from "./manufacturerform/EditManufacturer.vue";
export default {
  name: "ManufacturerTable",
  props: {
    displayedManufacturers: Array,
    showLoader: Number,
  },
  data() {
    return {
      API_URL: this.$manufacturerStorageURL,
    };
  },
  components: {
    EditManufacturer,
  },

  methods: {
    callModal: function(manufacturer) {
      this.$root.$emit("openEditManufacturer", manufacturer);
    },

    async deleteManufacturer(id) {
      var formData = new FormData();
      formData.append("id", id);
      this.$swal({
        title: "Are you sure you want to <br /> delete Manufacturer ?",
        text: "You can’t undo this action.",
        icon: "error",
        confirmButtonText: "Delete",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          return axios
            .post(
              process.env.VUE_APP_API_URL + "manufacturer/delete",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              if (response.data.statusCode == 200) {
                this.$root.$emit("removeManufacturer", id);
                this.$swal(
                  "Deleted!",
                  "Manufacturer has been deleted.",
                  "success"
                );
              } else {
                this.errorMessage = response.data.message;
              }
            });
        }
      });
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style></style>
